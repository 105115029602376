import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Button, Input, Spin, notification, Table, Form, Modal } from 'antd'
import { Helmet } from 'react-helmet'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import MainLayout from '../../../components/mainlayout'
import client from '../../../api/client'
import StatusTag from '../../../components/base/status'
import 'bootstrap/scss/bootstrap.scss'
const { confirm } = Modal

export default function NewBulkOrder(props) {
  const [addTrackingForm] = Form.useForm()
  const [editTrackingForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [bulkComment, setBulkComment] = useState('')
  const [bulkId, setBulkId] = useState(null)
  const [trackingList, setTrackingList] = useState([])
  const [orderList, setOrderList] = useState([])
  const [addTrackingModalVisible, setAddTrackingModalVisible] = useState(false)
  const [editTrackingModalVisible, setEditTrackingModalVisible] = useState(false)
  const submitBulkOrder = async () => {
    setLoading(true)
    await client
      .put(`/api/orders/bulkorders/${bulkId}`, { instruction: bulkComment })
      .then(async (res) => {
        if (orderList.length > 0) {
          notification.success({ message: 'Багц илгээмж үүслээ' })
          navigate('/')
        } else notification.error({ message: 'Захиалга нэмнэ үү!' })
      })
      .catch((error) => {
        console.error(error.message)
        notification.error({ message: 'Алдаа гарлаа' })
      })
    setLoading(false)
  }
  const trackListColumns = [
    {
      title: 'Трак дугаар',
      className: 'text-center',
      dataIndex: 'number',
    },
    {
      title: 'Хүлээн авсан өдөр',
      className: 'text-center',
      dataIndex: 'receivedDate',
      render: function (record) {
        return record ? <span>{moment(record).format('MM/DD/YYYY HH:mm A')}</span> : <span>Хүлээн аваагүй</span>
      },
    },
    {
      title: 'Статус',
      className: 'text-center',
      dataIndex: 'trackStatus',
      render: function (record) {
        return <StatusTag status={record} />
      },
    },
    {
      title: 'Тэмдэглэл',
      className: 'text-center',
      dataIndex: 'comment',
    },
    {
      title: '',
      className: 'text-center',
      dataIndex: 'id',
      render: function (record) {
        return (
          <>
            <div>
              <Button
                type="link"
                className="tracking-list-action-btn"
                onClick={() => {
                  getTrackingNumber(record)
                }}
              >
                Засварлах
              </Button>{' '}
              /
              <Button
                type="link"
                className="tracking-list-action-btn"
                style={{
                  color: '#F8031B',
                }}
                onClick={() => {
                  confirm({
                    title: 'Энэ трак дугаарыг устгахдаа итгэлтэй байна уу?',
                    icon: <ExclamationCircleOutlined />,
                    cancelText: 'Үгүй',
                    okText: 'Тийм',
                    okType: 'danger',
                    className: 'confirm-hs-modal',
                    centered: true,
                    confirmLoading: true,
                    onOk() {
                      deleteTrackingNumber(record)
                    },
                  })
                }}
              >
                Устгах
              </Button>
            </div>
          </>
        )
      },
    },
  ]
  const orderListColumns = [
    {
      title: 'Код',
      className: 'text-center',
      dataIndex: 'code',
    },
    {
      title: 'Бүртгэсэн огноо',
      className: 'text-center',
      dataIndex: 'createdDate',
      responsive: ['md'],
      render: function (record) {
        return record ? moment(record).format('MM/DD/YYYY') : ''
      },
    },
    {
      title: 'Илгээгч',
      className: 'text-center',
      dataIndex: 'sender',
      responsive: ['md'],
      render: function (record) {
        return record ? record : null
      },
    },
    {
      title: 'Хүлээн авагч',
      className: 'text-center',
      dataIndex: 'receiver',
      responsive: ['md'],
      render: function (record) {
        return record ? record : null
      },
    },
    {
      title: 'Илгээмжийн төрөл',
      className: 'text-center',
      dataIndex: 'shipmentType',
      responsive: ['md'],
    },
    {
      title: 'Тэмдэглэл',
      className: 'text-center',
      dataIndex: 'customerComment',
      responsive: ['md'],
    },
    {
      title: 'Статус',
      className: 'text-center',
      dataIndex: 'customerStatus',
      responsive: ['md'],
      render: function (record) {
        return <StatusTag status={record} />
      },
    },
    {
      title: '',
      className: 'text-center',
      dataIndex: 'id',
      render: function (record) {
        return (
          <>
            <div>
              <Button
                type="link"
                className="tracking-list-action-btn"
                onClick={() => {
                  navigate(`/bulk/${bulkId}/order/${record}`)
                }}
              >
                Засварлах
              </Button>{' '}
              /
              <Button
                type="link"
                className="tracking-list-action-btn"
                style={{
                  color: '#F8031B',
                }}
                onClick={() => {
                  confirm({
                    title: 'Энэ захиалгыг устгахдаа итгэлтэй байна уу?',
                    icon: <ExclamationCircleOutlined />,
                    cancelText: 'Үгүй',
                    okText: 'Тийм',
                    okType: 'danger',
                    className: 'confirm-hs-modal',
                    centered: true,
                    confirmLoading: true,
                    onOk() {
                      deleteOrder(record)
                    },
                  })
                }}
              >
                Устгах
              </Button>
            </div>
          </>
        )
      },
    },
  ]
  useEffect(() => {
    if (props.params.id) {
      setBulkId(props.params.id)
    }
  }, [props.params.id])
  useEffect(() => {
    if (bulkId) {
      client.get(`/api/orders/bulkorders/${bulkId}`).then(async (res) => {
        setTrackingList(res.data.tracks)
        setOrderList(res.data.orders)
        setBulkComment(res.data.instruction)
      })
    }
  }, [bulkId])
  const getBulk = async () => {
    if (bulkId) {
      client.get(`/api/orders/bulkorders/${bulkId}`).then(async (res) => {
        setTrackingList(res.data.tracks)
        setOrderList(res.data.orders)
        setBulkComment(res.data.instruction)
      })
    }
  }
  const addTrackingNumber = async () => {
    if (bulkId) {
      await addTrackingForm
        .validateFields()
        .then(async (values) => {
          addTrackingForm.resetFields()
          await client
            .post(`/api/orders/bulkorders/${bulkId}/tracks`, values)
            .then(async (res) => {
              notification.success({
                message: 'Трак дугаар амжилттай нэмэгдлээ',
              })
              setAddTrackingModalVisible(false)
              getBulk()
            })
            .catch((error) => {
              console.error(error.message)
              notification.error({ message: 'Алдаа гарлаа' })
            })
        })
        .catch((info) => {
          console.error('Validate Failed:', info)
        })
    } else {
      navigate('/')
    }
  }
  const editTrackingNumber = async () => {
    if (bulkId) {
      await editTrackingForm
        .validateFields()
        .then(async (values) => {
          await client
            .put(`/api/orders/tracks/${values.trackId}`, values)
            .then(async (res) => {
              editTrackingForm.resetFields()
              notification.success({
                message: 'Трак дугаар амжилттай засагдлаа',
              })
              setEditTrackingModalVisible(false)
              getBulk()
            })
            .catch((error) => {
              console.error(error.message)
              notification.error({ message: 'Алдаа гарлаа' })
            })
        })
        .catch((info) => {
          console.error('Validate Failed:', info)
        })
    }
  }
  const getTrackingNumber = async (trackId) => {
    if (bulkId) {
      await client
        .get(`/api/orders/tracks/${trackId}`)
        .then(async (res) => {
          setEditTrackingModalVisible(true)
          editTrackingForm.setFieldsValue({
            trackId: res.data.id,
            number: res.data.number,
            comment: res.data.comment,
          })
        })
        .catch((error) => {
          console.error(error.message)
          notification.error({ message: 'Алдаа гарлаа' })
        })
    } else navigate('/')
  }
  const deleteTrackingNumber = async (trackId) => {
    if (bulkId) {
      await client
        .delete(`/api/orders/tracks/${trackId}`)
        .then(async (res) => {
          notification.success({ message: 'Трак устгагдлаа' })
          getBulk()
        })
        .catch((error) => {
          console.error(error.message)
          notification.error({ message: 'Алдаа гарлаа' })
        })
    } else navigate('/')
  }
  const deleteOrder = async (orderId) => {
    if (orderId) {
      await client
        .delete(`/api/orders/${orderId}`)
        .then(async (res) => {
          notification.success({ message: 'Илгээмж устгагдлаа' })
          getBulk()
        })
        .catch((error) => {
          console.error(error.message)
          notification.error({ message: 'Алдаа гарлаа' })
        })
    } else navigate('/')
  }
  return (
    <Spin spinning={loading}>
      <Helmet title="Багц илгээмж бүртгэх | SB Express" defer={false} />
      <MainLayout>
        <Row>
          <Col span={24}>
            <div>
              <Button
                type="link"
                className="sb-arrow-back"
                onClick={() => {
                  navigate('/')
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                <span>Буцах</span>
              </Button>
            </div>
            <div className="text-center sb-text-large">Багц илгээмж</div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-3">
            <h4>Трак дугаарын мэдээлэл ({trackingList.length})</h4>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-3">
            <Table
              className="sb-table"
              columns={trackListColumns}
              dataSource={trackingList}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
              loading={false}
              pagination={false}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col flex="auto"></Col>
          <Col flex="300px">
            <Button
              className="ant-btn w-100 btn-sb btn-sb-primary"
              onClick={() => {
                setAddTrackingModalVisible(true)
              }}
            >
              Трак бүртгэх
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-3">
            <h4>Захиалгууд ({orderList.length})</h4>
          </Col>
          <Col span={24}>
            <Table
              className="sb-table mt-3"
              columns={orderListColumns}
              dataSource={orderList}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
              loading={false}
              pagination={false}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col flex="auto"></Col>
          <Col flex="300px">
            <Button
              className="ant-btn w-100 btn-sb btn-sb-primary"
              onClick={() => {
                navigate(`/bulk/${bulkId}/order/add`)
              }}
            >
              Захиалга бүртгэх
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-3">
            <h4>Тайлбар</h4>
          </Col>
          <Col span={10}>
            <Input.TextArea
              rows={4}
              className="sb-shadow sb-input-area"
              placeholder="Нэмэлт тайлбар оруулна уу"
              style={{ fontSize: '20px', padding: '11px' }}
              value={bulkComment}
              onChange={(e) => {
                setBulkComment(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row className="mb-5 mt-4">
          <Col span={16} offset={4}>
            <Button className="btn-sb btn-sb-primary w-100" style={{ fontSize: 18, height: 50, fontWeight: '500' }} onClick={submitBulkOrder}>
              Хадгалах
            </Button>
          </Col>
        </Row>
        <Modal
          title="Шинэ трак дугаар"
          open={addTrackingModalVisible}
          onCancel={() => {
            setAddTrackingModalVisible(false)
          }}
          className="sb-modal sb-modal-kiosk"
          width={400}
          destroyOnClose
          centered
          footer={[
            <Row justify="center" key={'modal'}>
              <Col span={24}>
                <Button className="btn-sb btn-sb-primary w-100" onClick={() => addTrackingNumber()}>
                  Хадгалах
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form form={addTrackingForm}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: 'Трак дугаараа оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Трак дугаар" className="sb-input sb-shadow" style={{ height: 60 }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="comment" className="mb-0">
                  <Input.TextArea
                    rows={4}
                    className="sb-shadow sb-input-area"
                    placeholder="Тэмдэглэл"
                    style={{ fontSize: '20px', padding: '11px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Трак дугаар засах"
          open={editTrackingModalVisible}
          onCancel={() => {
            setEditTrackingModalVisible(false)
          }}
          className="sb-modal sb-modal-kiosk"
          width={400}
          destroyOnClose
          centered
          footer={[
            <Row justify="center" key={'modal'}>
              <Col span={24}>
                <Button className="btn-sb btn-sb-primary w-100" onClick={() => editTrackingNumber()}>
                  Хадгалах
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form form={editTrackingForm}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item name="trackId" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: 'Трак дугаараа оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Трак дугаар" className="sb-input sb-shadow" style={{ height: 60 }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="comment" className="mb-0">
                  <Input.TextArea
                    rows={4}
                    className="sb-shadow sb-input-area"
                    placeholder="Тэмдэглэл"
                    style={{ fontSize: '20px', padding: '11px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </MainLayout>
    </Spin>
  )
}
